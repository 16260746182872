ngx-mui-datatable {

  -webkit-user-select: initial!important;
  -moz-user-select: initial!important;
  -ms-user-select: initial!important;
  -o-user-select: initial!important;
  user-select:initial!important;
  
 }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #3c6382;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #3c6382;
}
.cdk-overlay-container, .cdk-overlay-pane { z-index: 9999 !important; }

.row-highlighted {
  background: #73dabb;
}

.tab-container{
  .tab-content {
    background-color: #F8FAFD;
    border-width: 0px;
  }
}


.nav-tabs {
  margin: 10px 0px 0px 16px;
}

.card-green-background {
  background-color: #3fc380 !important;
}

.btn-primary {
  background-color: #1F272C;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus {
  span {
    color: white;
  }
  border-radius: 9px;
  border-color: white;
  background-color: #0a3d62;
}

.card {
    border-radius: 15px;
    border-width: 0px;
    background-color:#FFFFFF;
    box-shadow: 2px 0px 22px 0px rgb(0 0 0 / 21%);

  .tab-selection {
    border-radius: 15px;
  }

  .card-header {
    background-color: #FFFFFF;
    border-width: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px 0px 10px 19px;
  }

  .form-group {
    margin: 0px 20px;

    .form-title {
      margin-top: 10px;
      color: #97a5c1;
    }
    
  }

  .card_title {
    font-size: 16px;
    color: #2F2E41;
    font-weight: 600;
    padding: 3px 0 12px 0;
  }

  .text-value {
    font-size: 16px;
    color: #2F2E41;
    padding: 3px 0 0px 0;
    font-weight: 600;
  }
  
  .card-padding {
    padding:7px 52px;
  }

 .radio-selection-padding {
   padding-top: 17px;
 }
  .card-body {
    padding: 1rem;

    .card-title {
      margin-left: 20px;
    }
    .big  {
      margin-left: 20px;
    }
    .no-padding {
      padding: 0px;
    }
    .table {
      .form-check-input {
        position: sticky;
        margin-top: 0px;
        margin-left: 0px;
      }
      text-align: center;
      th {
        font-weight: 500;
        color: #8F9BAE;

      }
      td {
        border-top:1px solid #dee2e6;
        font-weight: 600;
      }
      tbody {
        .clickable {
          background-color:#FEFEFE;
        }
      }
    }
  }
}

.badge {
  .active 
  .danger {
    width: 70px;

  }
}
.action-button {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
}

.add-filter-img {
  background-image: url("/assets/icons/analytics/plus_icon.png");
}

.remove-filter-img {
  align-self: center;
  margin-bottom: 14px;
  margin-right: 15px;
  background-image: url("/assets/icons/analytics/remove_icon.png");
}

.remove-selection-filter-img {
  background-image: url("/assets/icons/analytics/remove_icon.png");
  width: 15px;
  height: 15px;
}

.datepicker {
  background-color: white;
  .ngb-dp-header {
    background-color: white;
  }
  .ngb-dp-content {
    background-color: white;
  }

  .custom-day.range {
      border-radius: 17px;
  }

  .custom-select {
    margin: 10px 5px;
    border-radius: 15px;
  }

  .custom-day.faded {
    border-radius: 0px;
  }

  .ngb-dp-weekday {
    color: #00DAB4;
  }
}
    
.input {
  width: 200px;
  .input-group {
    width: 200px;
  }
}

a {
  color: white;
}
a:hover {
  color: white;
}

.button {
  margin: 0px 5px 0px 5px;
  background-color: #0a3d62;
  border-radius: 5px;
  color: white;
  border-radius: 7px;
  border-width: 0px;
  padding: 0px 10px 0px 10px;
  font-family: Roboto;
  height: 35px;;
  margin-bottom: 10px;

  .right-side {
    float: right;

  }

  .left-side {
    float: right;
  }

  .center {
    float: center;
  }

  .add-button {
    margin-bottom: 10px;
  }
}


.sidebar  {
  text-align: left;
  font-weight: 400;
  .nav-link {
    padding-left: 26px;
  }
  .nav-link.active,
  .nav-link.active:hover  {
    background-color: #648EEB;
    .nav-icon {
      color: white;
    }
  }
}

.sidebar .nav-link.active, 
.sidebar .nav-link:hover {
    background-color: #648EEB;
  }

.mat-ink-bar {
  background-color: #3fc380 !important;

}

.mat-form-field-100w {
  width: 100%;
}
