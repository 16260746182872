// If you want to override variables do it here

// Import styles
@import "../node_modules/@coreui/coreui/scss/coreui";
@import "./custom/datepicker.scss";
// If you want to add something do it here

@import "../app/app.component.scss";

@import "~@coreui/coreui/dist/css/coreui.min.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}
.mat-tab-body-content {
  overflow: hidden !important;
}

.ag-floating-bottom-viewport {
  background-color: #3c6382 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ag-floating-bottom-container {
  div {
    background-color: #3c6382 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #3c6382 !important;
  }
}

.ag-header-viewport {
  background-color: white;
  border-bottom: 1px solid #cfcfde;
}

.ag-row:hover {
  background-color: rgba(207, 207, 222, 0.2);
}

.ag-center-cols-container {
  min-width: 100% !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

.alert-active {
  & span {
    color: red;
  }
}

.alert-inactive {
  & span {
    color: black;
  }
}

.rag-active {
  & span {
    padding: 2px 15px 3px;
    color: white;
    background-color: #3c6382;
    border-radius: 8px;
  }
}

.rag-inactive {
  & span {
    padding: 2px 15px 3px;
    color: white;
    background-color: #cfcfde;
    border-radius: 8px;
  }
}

.remove {
  & span {
    padding: 2px 15px 3px;
    color: white;
    background-color: #fd6584;
    border-radius: 8px;
  }
}

.ag-cell {
  color: #2f2e41;
}

.ngb-dp-navigation-select {
  position: absolute;
  top: -17%;
  right: 14%;
  width: 50%;
}

.ag-root ::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.ag-root ::-webkit-scrollbar-button {
  background: #f8f9fa;
}

.ag-root ::-webkit-scrollbar-track-piece {
  background: #f8f9fa;
}

.ag-root ::-webkit-scrollbar-thumb {
  background: #5f6569;
  border-radius: 6px;
}

.ag-root ::-webkit-scrollbar:horizontal {
  height: 10px;
  width: 10px;
}

.custom-select {
  max-width: 116px;
  border-radius: 10px;
  margin-right: 10px !important;
}

.custom-select {
  background-image: url("/assets/icons/dashboard/select-arrow.svg");
  background-position: calc(100% - 10px) 10px, calc(100% - 10px) 10px, 100% 0;
  background-size: 15px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

ngb-datepicker {
  border: none !important;
}

.ngb-dp-header,
.ngb-dp-weekdays,
.ngb-dp-month-name {
  background-color: white !important;
  color: #2f2e41 !important;
  font-weight: 700;
  border-bottom: none !important;
}

.ngb-dp-month-name {
  margin-bottom: 25px;
}

.ngb-dp-arrow-btn {
  position: absolute;
  top: 10%;
}

.ngb-dp-arrow-btn {
  position: absolute;
  top: 10%;
}

.ngb-dp-navigation-chevron {
  color: #3c6382 !important;
}

.ngb-dp-arrow {
  height: 0 !important;
}

.ngb-dp-weekday {
  color: #2f2e41 !important;
  font-weight: 600 !important;
}

.custom-day.range,
.custom-day:hover {
  background-color: #3c6382 !important;
  border-radius: 100%;
}

.custom-day {
  padding: 0.25rem !important;
}

.custom-day.faded {
  background: #3c638269 !important;
  border-radius: 0 !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #3c6382 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #3c6382 !important;
  z-index: 3;
}

.mat-radio-button .mat-radio-outer-circle {
  border-color: #3c6382 !important; // Override material
  z-index: 3;
}

.mat-select-search-input {
  background-color: white !important;
}

.toast-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
